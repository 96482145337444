import React, { Component } from 'react';

class WormholeCanvas extends Component {
  componentDidMount() {
    this.canvas = this.refs.canvas;
    this.ctx = this.canvas.getContext('2d');
    this.width = this.canvas.width = window.innerWidth;
    this.height = this.canvas.height = window.innerHeight;
    this.centerX = this.width / 2;
    this.centerY = this.height / 2;

    this.stars = this.createStars(300); // Cantidad de estrellas
    this.drawLoop();
  }

  createStars(num) {
    const stars = [];
    for (let i = 0; i < num; i++) {
      stars.push({
        x: Math.random() * this.width - this.centerX,
        y: Math.random() * this.height - this.centerY,
        z: Math.random() * this.width,
      });
    }
    return stars;
  }

  drawStars = () => {
    const { ctx, centerX, centerY, width, height } = this;
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, width, height);

    for (let star of this.stars) {
      star.z -= 5; // Velocidad de acercamiento
      if (star.z <= 0) {
        star.z = width;
        star.x = Math.random() * this.width - centerX;
        star.y = Math.random() * this.height - centerY;
      }

      const k = 128 / star.z; // Perspectiva
      const sx = star.x * k + centerX;
      const sy = star.y * k + centerY;

      const radius = (1 - star.z / width) * 2; // Tamaño relativo

      ctx.beginPath();
      ctx.fillStyle = 'rgba(0, 200, 255, 0.8)';
      ctx.arc(sx, sy, radius, 0, Math.PI * 2);
      ctx.fill();
    }
  }

  drawLoop = () => {
    this.drawStars();
    this.animationFrame = requestAnimationFrame(this.drawLoop);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationFrame);
  }

  render() {
    return (
      <canvas ref="canvas" style={{ display: 'block', background: 'black' }} />
    );
  }
}

export default WormholeCanvas;
