import React, { Component } from 'react'
import { CardBody, CardTitle, Col, Row, Card } from 'react-bootstrap'
import { LoadProduction } from '../store/MetaStore'
import LinkPreview from './Common/LinksPreview'

export default class Tutoriales extends Component {
  constructor(props) {
    super(props)
    this.state = LoadProduction.init
  }
  render() {
    return (
      <div className='App-header'>
        <header >
          <h2>Tutoriales</h2>
        </header>
        <div className='w-50'>
          <div className='App-header'>
            <Row >
              <Col>
                <Card className='style-card-back-v'>
                  <CardTitle>Tu tienda online en menos de 2 min</CardTitle>
                  <CardBody >
                    <iframe
                      width="248px"
                      height="260px"
                      src="https://www.youtube.com/embed/CK2_wZSNH2A?si=JRAz-RVtxMVTRo9a"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </CardBody>
                </Card>
              </Col>
              <Col>
              <Card className='style-card-back-v'>
                  <CardTitle>Aprende a utilizar mi sistemita</CardTitle>
                  <CardBody >
                    <iframe
                      width="248px"
                      height="260px"
                      src="https://www.youtube.com/embed/eM7Kk7a-5V8?si=u5XgQNeK5Z-U3z_4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <footer>
          <Row>
            <Col>
            <LinkPreview url={'https://misistemita.store/lasollas'}/>
              <p>Mi sistemita cuenta con una demostracion con la cual podras adaptarte a sus mecanicas</p>
              <p><a href="https://misistemita.store/misistemita" >https://misistemita.store/misistemita</a></p>
              <br />
              <p><a href="https://misistemita.store/admin/misistemita" >https://misistemita.store/admin/misistemita</a><span> contraseña: misistemita2025</span></p>
              <br />
              <p><a href="https://misistemita.store/admin/misistemita" >https://misistemita.store/monitor/misistemita</a></p>
            </Col>
          </Row>
          <h4></h4>
        </footer>

        <p>

        </p>
        <br />
        <p>

        </p>
      </div>
    )
  }
}
