import React, { Component } from "react";

class LinkPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenshotUrl: "",
            loading: true,
        };
    }

    componentDidMount() {
        this.fetchScreenshot();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            this.fetchScreenshot();
        }
    }

    async fetchScreenshot() {
        this.setState({ loading: true });

        const apiKey = "SQYbzNfxH15sjw"; // Reemplaza con tu clave de ScreenshotOne u otra API
        const apiUrl = `https://api.screenshotone.com/take?access_key=${apiKey}&url=${encodeURIComponent(
            this.props.url
        )}&full_page=false&format=jpeg&width=600&height=400`;

        try {
            const response = await fetch(apiUrl);
            const data = await response.blob();
            const imageUrl = URL.createObjectURL(data);
            console.log(data)

            this.setState({ screenshotUrl: imageUrl, loading: false });
        } catch (error) {
            console.error("Error obteniendo la captura:", error);
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <div className="link-preview-container">

                {this.state.loading ? (
                    <p>Cargando...</p>
                ) : (
                    <>
                        <h3>Vista Previa</h3>
                        <a href={this.props.url} target="_blank" rel="noopener noreferrer">
                            <img
                                src={this.state.screenshotUrl}
                                alt="Preview"
                                className="preview-image"
                            />
                        </a>
                    </>
                )}
            </div>
        );
    }
}

export default LinkPreview