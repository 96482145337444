import React, { Component } from 'react'
import { LoadApp, LoadProduction } from '../store/MetaStore'
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FloatingLabel, Form, FormCheck, FormControl, InputGroup, Modal, Row, Table } from 'react-bootstrap'
import { CiGrid2H, CiGrid2V, CiGrid41 } from "react-icons/ci";
import LoadingSimulator from './LoadingSimulator';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import db, { storage } from '../firebase';
import UserTicket from './Common/UserTicket';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import estadosMunicipios from './Common/EstadosMunicipios';
import AlertMsn from './Common/AlertMsn';
import axios from 'axios';


export default class AdminStyleMain extends Component {

    constructor(props) {
        super(props)
        this.state = LoadProduction.init
        this.state.nextInsideFrame = false
        this.state.rowStyleMld = {
            height: '284px',        
            overflow: 'auto',
            flexWrap: 'nowrap',
            alignItems: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        };
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleEstadoChange = this.handleEstadoChange.bind(this)
        this.handleMunicipioChange = this.handleMunicipioChange.bind(this)
        this.handleMailChange = this.handleMailChange.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleMailCode = this.handleMailCode.bind(this)
        this.handleCloseModalCode = this.handleCloseModalCode.bind(this)
        this.handleCategory = this.handleCategory.bind(this)
        this.canvasRef = React.createRef();
    }



    componentDidMount() {
        this.setState({ modalAlert: false })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalAlert && !prevState.modalAlert) {
            this.autoCloseTimer = setTimeout(() => {
                this.handleCloseAlert();
            }, 2000);
        }

        if (!this.state.modalAlert && prevState.modalAlert) {
            clearTimeout(this.autoCloseTimer);
        }
    }

    handleCloseAlert() {
        this.setState({ modalAlert: false });
    };

    handleAlertMsn(a) {
        const txtAlerts = {
            b: {
                modalAlert: true,
                txtMainAlert: 'El mail ya está registrado.',
                txtTitleAlert: 'Se te fue el rollo',
                alertState: 'warning',
            },
            emptyFields: {
                modalAlert: true,
                txtMainAlert: 'No dejes ningún campo en blanco.',
                txtTitleAlert: 'Al tiro',
                alertState: 'warning',
            },
            siteExists: {
                modalAlert: true,
                txtMainAlert: 'Este sitio ya existe. Comunícate con el administrador.',
                txtTitleAlert: '¡Cuidado!',
                alertState: 'warning',
            },
            verificationFailed: {
                modalAlert: true,
                txtMainAlert: 'El código ingresado no es válido.',
                txtTitleAlert: 'Verificación fallida',
                alertState: 'danger',
            },
            verificationSuccess: {
                modalAlert: true,
                txtMainAlert: 'Código verificado correctamente.',
                txtTitleAlert: '¡Éxito!',
                alertState: 'success',
            },
            mailSent: {
                modalAlert: true,
                txtMainAlert: 'Código enviado a tu correo.',
                txtTitleAlert: 'Correo enviado',
                alertState: 'info',
            },
            mailError: {
                modalAlert: true,
                txtMainAlert: 'Error al enviar el correo. Inténtalo más tarde.',
                txtTitleAlert: '¡Ups!',
                alertState: 'danger',
            },
            codeIncomplete: {
                modalAlert: true,
                txtMainAlert: 'El codigo de verificacion esta incompleto',
                txtTitleAlert: 'Le faltan numeros!!',
                alertState: 'warning',
            }
        };

        const alert = txtAlerts[a] || {
            modalAlert: true,
            txtMainAlert: 'Ha ocurrido un error desconocido.',
            txtTitleAlert: 'Error',
            alertState: 'danger',
        };
        this.setState(alert);
    }

    handleEstadoChange = (event) => {
        const estado = event.target.value;
        this.setState({
            txtUserZoneA: estado,
            txtUserRegionB: estadosMunicipios[estado] || [],
            txtUserZoneB: '',
        });
    };

    handleMunicipioChange(event) {
        this.setState({
            txtUserZoneB: event.target.value
        });
    };


    nextStep() {
        this.setState({ nextInsideFrame: true })      
    }

    handleInputChange(event) {
        const newValue = event.target.value;
        this.setState((prevState) => {
            const updatedBtnTxtTitle = [...prevState.btnTxtTitle];
            updatedBtnTxtTitle[6] = newValue;
            return { btnTxtTitle: updatedBtnTxtTitle };
        });
    }

    toggleCheck(checkId) {
        this.setState((prevState) => ({
            selectedCheck: prevState.selectedCheck === checkId ? null : checkId
        }));
    }

    async midleStep() {
        const { txtSecuryCode, siteName, txtUserMail, txtUserName, txtUserPass, codeUse } = this.state;
        const fullCode = this.state.txtSecuryCode.join('');
        if (fullCode.length < 6) {
            this.setState({ isValidCode: false });
            this.handleAlertMsn('codeIncomplete');
            return;
        }

        if (this.state.mailCode !== fullCode) {
            this.handleAlertMsn('verificationFailed');
        } else {

            if (siteName && txtUserMail && txtUserName && txtUserPass) {
                const licenseRef = doc(db, '$:__sites-name', siteName);
                const isLicenseAvailable = await getDoc(licenseRef);

                if (isLicenseAvailable.exists()) {
                    this.handleAlertMsn('siteExists');
                    this.setState({ siteName: '' });
                } else {
                    this.setState({ nextInsideFrame:true, lastStep: true });
                }
            } else {
                this.handleAlertMsn('emptyFields');
            }
        }
    }

    handleExportPDF = () => {
        UserTicket(this.state)
        setTimeout(() => {
            window.location = '/admin/' + this.state.siteName
        }, 1200)
    }

    async finishStep() {

        const { tags } = this.state;
        const license = await doc(db, '$:__sites-custumer', this.state.txtUserMail)
        const isLicenseAvalible = await getDoc(license)
        if (isLicenseAvalible.exists()) {
            setTimeout(() => {
                this.handleAlertMsn('b')
                window.location = '/Creatuapp'
            }, 750);

        } else {

            this.setState({
                utaStep: false,
                tags: [...tags, { name: 'pointItemSubName', value: 0 }],
                itemTags: tags,
                modalWelcome: false,
            })

            const monitor = {
                ticketsNow: [],
                aTicketsCC: [],
                aTicketsSS: [],
            }

            await setDoc(doc(db, '$:__sites-custumer', this.state.txtUserMail), {
                siteName: this.state.siteName,
                siteMetaData: this.state,
                createdDate: new Date().toLocaleString(),
            });
            await setDoc(doc(db, '$:__sites-name', this.state.siteName), this.state);
            await setDoc(doc(db, '$:__site-monitor', this.state.siteName), this.state);

            this.handleExportPDF()
            this.setState({ nextInsideFrame:true, lastStep: true })
        }

    }


    handleChangeTitleSite(e) {
        this.setState({ siteName: e.target.value.toLowerCase() })
    }

    handleCategory(e) {
        this.setState({
            userTypeApp: e
        })
    }

    handleMailChange(e) {
        const email = e.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.setState({
            txtUserMail: email,
            isValidMail: emailRegex.test(email),
        });
    }

    async handleMailView() {
        const { txtUserMail, mailCode } = this.state;

        if (!txtUserMail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(txtUserMail)) {
            this.handleAlertMsn('emptyFields');
            return;
        }
        if (!this.state.codeSendWait) {
            this.setState({ codeSendWait: true, modalCode: true, });

            const licenseRef = doc(db, '$:__site-key', mailCode);

            try {
                await setDoc(licenseRef, { codeUse: mailCode }, { merge: true });

                const payload = {
                    verificationCode: mailCode,
                    recipient: txtUserMail,
                };

                await axios.post('https://backensinfree4a.online/send-email', payload);

                this.setState({ mailCode, codeSendWait: true });
                this.handleAlertMsn('mailSent');

            } catch (error) {
                this.handleAlertMsn('mailError');
            }
        } else {
            this.setState({ modalCode: true });
        }
    }

    handleCloseModalCode = () => {
        this.setState({ modalCode: false });
    };

    handleMailCode(e, i) {
        if (!/^\d?$/.test(e)) return;

        const newCode = [...this.state.txtSecuryCode];
        newCode[i] = e;
        this.setState({ txtSecuryCode: newCode });

        if (e && i < newCode.length - 1) {
            document.getElementById(`digit-${i + 1}`).focus();
        }

    }

    handlePaste = (e) => {

        const clipboardData = e.clipboardData || window.clipboardData;
        const pasteData = clipboardData.getData('text').slice(0, 6);

        if (!/^\d+$/.test(pasteData)) return;

        const newCode = pasteData.split('').concat(new Array(6 - pasteData.length).fill(''));

        this.setState({ txtSecuryCode: newCode });

        const filledIndex = Math.min(pasteData.length - 1, 5);
        document.getElementById(`digit-${filledIndex}`).focus();
    };


    handleUpdate() {
        const {
            pointBtnGradientDirection,
            pointBtnColorAlpha,
            pointBtnColorBetha,
        } = this.state;

        this.setState({
            choiseBtnColor: {
                fontStyle: "normal",
                backgroundImage: `linear-gradient(to ${pointBtnGradientDirection}, ${pointBtnColorAlpha}, ${pointBtnColorBetha})`,
            },
        });
    }

    handleDirectionChange = (e) => {
        this.setState(
            { pointBtnGradientDirection: e.target.value },
            this.handleUpdate
        );
    };

    handleColor1Change = (e) => {
        this.setState(
            { pointBtnColorAlpha: e.target.value },
            this.handleUpdate
        );
    };

    handleColor2Change = (e) => {
        this.setState(
            { pointBtnColorBetha: e.target.value },
            this.handleUpdate
        );
    };

    render() {
        const { choiseBtnColor, pointBtnColorAlpha, pointBtnGradientDirection, pointBtnColorBetha, isValidCode, codeSendWait, modalCode, txtSecuryCode, txtUserRegionA, txtUserRegionB, txtUserZoneA, txtUserZoneB, } = this.state;

        return (
            <>
                <AlertMsn
                    modalAlert={this.state.modalAlert}
                    alertState={this.state.alertState}
                    txtTitleAlert={this.state.txtTitleAlert}
                    txtMainAlert={this.state.txtMainAlert}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <canvas ref={this.canvasRef} style={{ display: 'none' }} />
                <Card className='card-width m-tb-5'>
                    <CardTitle></CardTitle>
                    <CardHeader>
                        {this.state.nextInsideFrame === false ?
                            <CardTitle>{this.state.txtTitle[3]}</CardTitle>
                            : this.state.lastStep === false ?
                                <CardTitle>{this.state.txtTitle[3]}</CardTitle> :
                                <CardTitle>{this.state.txtTitle[4]}</CardTitle>}
                    </CardHeader>
                    {this.state.nextInsideFrame === false ?
                        <CardBody>
                            <Row>
                                <CardBody>
                                    <InputGroup>
                                        <FormControl
                                            placeholder='Nombre completo'
                                            type='text'
                                            onChange={(e) => { this.setState({ txtUserName: e.target.value }) }}
                                        />
                                    </InputGroup>

                                </CardBody>
                                <CardBody>
                                    <InputGroup>
                                        <FormControl
                                            placeholder="e-mail"
                                            type="mail"
                                            value={this.state.txtUserMail}
                                            onChange={this.handleMailChange}
                                            style={{
                                                borderColor: this.state.isValidMail ? '#ced4da' : 'red',
                                                boxShadow: this.state.isValidMail ? 'none' : '0 0 0 0.2rem rgba(255,0,0,0.25)',
                                            }}
                                        />
                                    </InputGroup>
                                    <br />
                                    <Button onClick={() => this.handleMailView()}>
                                        {codeSendWait ? 'Ingresar Código' : 'Solicitar Código'}
                                    </Button>
                                </CardBody>
                                <CardBody>
                                    <div className='w-100'>
                                        <label className='f-s-1'>Estado:</label>
                                        <select value={txtUserZoneA} onChange={this.handleEstadoChange}>
                                            <option className='f-s-1' value="">Seleccione un estado</option>
                                            {txtUserRegionA.map((estado) => (
                                                <option key={estado} value={estado}>
                                                    {estado}
                                                </option>
                                            ))}
                                        </select>

                                        <label >Municipio:</label>
                                        <select className='font-size-2' value={txtUserZoneB} onChange={this.handleMunicipioChange}>
                                            <option value="">Seleccione un municipio</option>
                                            {txtUserRegionB.map((municipio) => (
                                                <option key={municipio} value={municipio}>
                                                    {municipio}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <InputGroup >
                                        <FloatingLabel
                                            controlId="floatingSelectGrid"
                                            label="misistemita.store/"
                                        >
                                            <FormControl
                                                className='h-80'
                                                placeholder='Nombre de tu app'
                                                onChange={(e) => { this.handleChangeTitleSite(e) }}
                                            /></FloatingLabel>
                                    </InputGroup>
                                </CardBody>
                                <CardBody>
                                    <InputGroup >
                                        <FormControl
                                            className='h-80'
                                            placeholder='Contraseña de Admin'
                                            type='password'
                                            onChange={(e) => { this.setState({ txtUserPass: e.target.value }) }}
                                        />
                                    </InputGroup>
                                </CardBody>
                                <hr />

                                <CardBody>
                                    <CardHeader>Enlaces</CardHeader>
                                    <CardTitle>Panel de administrador</CardTitle>
                                    <p>misistemita.store/admin/{this.state.siteName}</p>
                                    <CardTitle>Monitor</CardTitle>
                                    <p>misistemita.store/monitor/{this.state.siteName}</p>
                                    <CardTitle>Pagina principal</CardTitle>
                                    <p>misistemita.store/{this.state.siteName}</p>
                                </CardBody>
                                <CardBody>
                                    <span>Categoria</span>
                                    <InputGroup>
                                        <select value={this.state.userTypeApp} onChange={(e) => this.handleCategory(e.target.value)}>
                                            <option value="entretaiment">Entretenimiento</option>
                                            <option value="estilo">Stylo</option>
                                            <option value="food">Alimentos</option>
                                            <option value="office">Oficina</option>
                                            <option value="material">Materiales</option>
                                            <option value="tech">Tecnologia</option>

                                        </select>
                                    </InputGroup>
                                </CardBody>
                            </Row>
                        </CardBody> : <><LoadingSimulator config={this.state} />
                            <br />
                        </>}
                    <CardFooter>
                        {this.state.nextInsideFrame === false ?
                            <Button onClick={() => { this.midleStep() }}>{this.state.btnTxtTitle[1]}</Button>
                            : this.state.utaStep === false ? <Button onClick={() => { this.finishStep() }}>{this.state.btnTxtTitle[7]}</Button>
                                    : <></>
                        }</CardFooter>
                </Card>
                <Modal className='z-modal-con' show={modalCode} onHide={this.handleCloseModalCode} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Ingresa el Código de Seguridad</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='txt-cen'>
                        <Card>
                            <CardBody>
                                <div className='m-w-5 '>
                                    <span className='f-s-1'>Código de seguridad</span>
                                </div>
                                <InputGroup>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                        {txtSecuryCode.map((digit, index) => (
                                            <Form.Control
                                                key={index}
                                                id={`digit-${index}`}
                                                type="text"
                                                value={digit}
                                                maxLength={1}
                                                onChange={(e) => this.handleMailCode(e.target.value, index)}
                                                onPaste={this.handlePaste}
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    textAlign: "center",
                                                    borderColor: isValidCode ? "#ced4da" : "red",
                                                    boxShadow: isValidCode ? "none" : "0 0 0 0.2rem rgba(255,0,0,0.25)",
                                                }}
                                            />
                                        ))}

                                    </div>
                                </InputGroup>
                            </CardBody>
                        </Card>
                        <Button variant="primary" onClick={this.handleCloseModalCode}>
                            Confirmar
                        </Button>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
