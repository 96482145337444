import React, { Component } from 'react'
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import db from '../../firebase';
import { LoadProduction } from '../../store/MetaStore'
import { Card, Form, Button } from "react-bootstrap";
import AlertMsn from './AlertMsn';
import WormholeCanvas from './WormwholeCanvas';


export default class FrameDetails extends Component {
    constructor(props) {
        super(props)
        this.state = LoadProduction.init
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
    }

    cleanPathname() {
        const { pathname } = window.location;
        const decodedPathname = decodeURIComponent(pathname);
        return decodedPathname.startsWith('/admin/')
            ? decodedPathname.substring('/admin/'.length)
            : decodedPathname;
    }

    async componentDidMount() {

        const { pathname } = window.location;

        const cleanedPathname = this.cleanPathname()
        const license = await doc(db, '$:__sites-name', cleanedPathname)
        this.setState({ nameSite: cleanedPathname })
        if (cleanedPathname !== '') {
            const isLicenseAvalible = await getDoc(license)
            this.state = isLicenseAvalible.data()
            isLicenseAvalible.exists() ?
                this.setState({
                    ...isLicenseAvalible.data(),
                    pageExist: true
                })
                :
                setTimeout(() => {
                    alert('este sitio no existe'); window.location = '/'
                }, 1500);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalAlert && !prevState.modalAlert) {
            this.autoCloseTimer = setTimeout(() => {
                this.handleCloseAlert();
            }, 1450);
        }

        if (!this.state.modalAlert && prevState.modalAlert) {
            clearTimeout(this.autoCloseTimer);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.autoCloseTimer);
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCloseAlert() {
        this.setState({ modalAlert: false });
    }

    handleSave = async () => {
        this.handleCloseAlert()
        try {
            await updateDoc(doc(db, '$:__sites-name', this.state.siteName), this.state)
            this.setState({
                modalAlert: true,
                txtMainAlert: 'Configuración emparejada con el servidor',
                txtTitleAlert: 'Mi sistemita',
                alertState: 'success',
            });
        } catch (e) {
            this.setState({
                modalAlert: true,
                txtMainAlert: 'Error al emparejar con el servidor',
                txtTitleAlert: 'Mi sistemita',
                alertState: 'danger',
            })
        }
    }



    render() {
        const { txtUserName, txtUserPass, image, txtUserMail, siteName } = this.state;
        return (
            <div>
                <AlertMsn
                    modalAlert={this.state.modalAlert}
                    alertState={this.state.alertState}
                    txtTitleAlert={this.state.txtTitleAlert}
                    txtMainAlert={this.state.txtMainAlert}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <Card className="shadow-lg p-4 mx-auto" style={{ maxWidth: "400px", background: "rgba(255, 255, 255, 0.8)", borderRadius: "12px" }}>
                        <Card.Body>
                            <div className="text-center mb-3">
                                <img src={this.state.pointImgUser || this.state.noImg} alt="User" className="rounded-circle shadow" width="100" height="100" referrerpolicy="no-referrer" />
                            </div>

                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nombre de usuario</Form.Label>
                                    <Form.Control type="text" name="txtUserName" value={txtUserName} onChange={this.handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Correo Electrónico</Form.Label>
                                    <Form.Control type="txtUserMail" value={txtUserMail} readOnly />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Sitio Web</Form.Label>
                                    <Form.Control type="text" value={siteName} readOnly />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Nueva Contraseña</Form.Label>
                                    <Form.Control type="password" name="txtUserPass" value={txtUserPass} onChange={this.handleChange} placeholder="********" />
                                </Form.Group>

                                <Button variant="primary" onClick={this.handleSave} className="w-100 shadow">
                                    Guardar Cambios
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>               
                </div>         
            </div>
        )
    }
}
