import React, { Component } from 'react'
import { LoadProduction } from '../store/MetaStore'
import { collection, setDoc, doc, getDoc } from 'firebase/firestore';
import { auth, provider, signInWithPopup, signOut } from '../firebase';
import { FcGoogle } from "react-icons/fc";
import db, { storage } from '../firebase';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FloatingLabel, FormControl, InputGroup, Row } from 'react-bootstrap'
import UserTicket from './Common/UserTicket';
import AlertMsn from './Common/AlertMsn';

export default class StartPage extends Component {

    constructor(props) {
        super(props)
        this.state = LoadProduction.init;
    }

    componentDidMount() {
        this.setState({ modalAlert: false })
    }

    async nextStep() {
        this.props.nextFrame(false)
    }

    handleAlertMsn(a) {
        const txtAlerts = {
            b: {
                modalAlert: true,
                txtMainAlert: 'El mail ya está registrado.',
                txtTitleAlert: 'Se te fue el rollo',
                alertState: 'warning',
            },
            emptyFields: {
                modalAlert: true,
                txtMainAlert: 'No dejes ningún campo en blanco.',
                txtTitleAlert: 'Al tiro',
                alertState: 'warning',
            },
            siteExists: {
                modalAlert: true,
                txtMainAlert: 'Este sitio ya existe. Comunícate con el administrador.',
                txtTitleAlert: '¡Cuidado!',
                alertState: 'warning',
            },
            verificationFailed: {
                modalAlert: true,
                txtMainAlert: 'El código ingresado no es válido.',
                txtTitleAlert: 'Verificación fallida',
                alertState: 'danger',
            },
            verificationSuccess: {
                modalAlert: true,
                txtMainAlert: 'Código verificado correctamente.',
                txtTitleAlert: '¡Éxito!',
                alertState: 'success',
            },
            mailSent: {
                modalAlert: true,
                txtMainAlert: 'Código enviado a tu correo.',
                txtTitleAlert: 'Correo enviado',
                alertState: 'info',
            },
            mailError: {
                modalAlert: true,
                txtMainAlert: 'Error al enviar el correo. Inténtalo más tarde.',
                txtTitleAlert: '¡Ups!',
                alertState: 'danger',
            },
            codeIncomplete: {
                modalAlert: true,
                txtMainAlert: 'El codigo de verificacion esta incompleto',
                txtTitleAlert: 'Le faltan numeros!!',
                alertState: 'warning',
            },
            z: {
                modalAlert: true,
                txtMainAlert: 'Sera rederigido a su app',
                txtTitleAlert: 'Que no se te olvide',
                alertState: 'info',
            }

        };

        const alert = txtAlerts[a] || {
            modalAlert: true,
            txtMainAlert: 'Ha ocurrido un error desconocido.',
            txtTitleAlert: 'Error',
            alertState: 'danger',
        };
        this.setState(alert);
    }


    handleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;   
            this.setState({ user }, async () => {
                const license = await doc(db, '$:__sites-custumer', this.state.user.email)
                const isLicenseAvalible = await getDoc(license)
                if (isLicenseAvalible.exists()) {
                    setTimeout(() => {
                        this.handleAlertMsn('z')
                        window.location = '/admin/' + isLicenseAvalible.data().siteName + ''
                    }, 3700);
                }else{
                    this.setState({                       
                        modalWelcome: false,
                        txtUserMail: user.email,
                        txtUserName: user.displayName,
                        pointImgUser: user.photoURL,
        
                    })
                }
            });

        } catch (error) {
            console.error('Error durante el inicio de sesión:', error);
        }
    };

    handleLogout = async () => {
        try {
            await signOut(auth);
            this.setState({ user: null });
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };

    handleExportPDF = () => {
        UserTicket(this.state)
        setTimeout(() => {
            window.location = '/admin/' + this.state.siteName
        }, 1200)
    }


    async finishStep() {
        const { tags, user, ...stateWithoutUser } = this.state;

        if (this.state.siteName) {
            const licenseRef = doc(db, '$:__sites-name', this.state.siteName);
            const isLicenseAvailable = await getDoc(licenseRef);

            if (isLicenseAvailable.exists()) {
                this.handleAlertMsn('siteExists');
                this.setState({ siteName: '' });
                return
            }
        } else {
            this.handleAlertMsn('emptyFields');
            return
        }
        const license = await doc(db, '$:__sites-custumer', user.email)
        const isLicenseAvalible = await getDoc(license)
        if (isLicenseAvalible.exists()) {
            setTimeout(() => {
                this.handleAlertMsn('b')
                window.location = '/Creatuapp'
            }, 3500);

        } else {

            this.setState({
                utaStep: false,
                tags: [...tags, { name: 'pointItemSubName', value: 0 }],
                itemTags: tags,
                modalWelcome: false,
                txtUserMail: user.email,
                txtUserName: user.displayName,
                pointImgUser: user.photoURL,

            }, async () => {
                const monitor = {
                    ticketsNow: [],
                    aTicketsCC: [],
                    aTicketsSS: [],
                }


                await setDoc(doc(db, '$:__sites-custumer', user.email), {
                    siteName: this.state.siteName,
                    siteMetaData: stateWithoutUser,
                    createdDate: new Date().toLocaleString(),
                });

                await setDoc(doc(db, '$:__sites-name', this.state.siteName), stateWithoutUser);
                await setDoc(doc(db, '$:__site-monitor', this.state.siteName), stateWithoutUser);

                this.handleExportPDF()
                this.setState({ nextInsideFrame: true, lastStep: true })
            })

        }

    }

    handleChangeTitleSite(e) {
        this.setState({ siteName: e.target.value.toLowerCase() })
    }



    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalAlert && !prevState.modalAlert) {
            this.autoCloseTimer = setTimeout(() => {
                this.handleCloseAlert();
            }, 2000);
        }

        if (!this.state.modalAlert && prevState.modalAlert) {
            clearTimeout(this.autoCloseTimer);
        }
    }

    handleCloseAlert() {
        this.setState({ modalAlert: false });
    };



    render() {
        return (
            <>
                <AlertMsn
                    modalAlert={this.state.modalAlert}
                    alertState={this.state.alertState}
                    txtTitleAlert={this.state.txtTitleAlert}
                    txtMainAlert={this.state.txtMainAlert}
                    handleCloseAlert={this.handleCloseAlert}
                />
                {!this.state.user ? (
                    <Card className='w-85'>
                        <CardHeader>
                            <CardTitle>{this.state.txtTitle[0]}</CardTitle>
                        </CardHeader>
                        <Row>
                            <Col>
                                <CardBody>
                                    <p>Con unos sensillos pasos podras crear tu app</p>
                                </CardBody>
                                <div className="z-modal text-center">

                                    <Button
                                        style={{
                                            backgroundColor: "#ffffff",
                                            border: "1px solid #dddddd",
                                            color: "#555555",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "10px",
                                            width: "fit-content",
                                            padding: "10px 20px",
                                            borderRadius: "8px",
                                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        }}
                                        onClick={this.handleLogin}
                                    >
                                        <FcGoogle size={24} />
                                        Continuar...
                                    </Button>
                                    <br />
                                    <div style={{ height: '10px', width: '10px' }}></div>
                                </div>
                            </Col>
                        </Row>

                    </Card>) : (
                    <>
                        <div>
                            <div className="input-container">
                                <div className="input-wrapper">
                                    <span className="input-prefix">misistemita.store/</span>
                                    <input
                                        type="text"
                                        className="custom-input"
                                        placeholder="Nombre de tu app"
                                        onChange={(e) => { this.handleChangeTitleSite(e) }}
                                    />
                                </div>
                                <div style={{ bottom: '25px', position: 'absolute' }}>
                                    <Button onClick={() => { this.finishStep() }}>{this.state.btnTxtTitle[7]}</Button>
                                </div>
                            </div>

                        </div>

                    </>

                )
                }
            </>
        )
    }
}
