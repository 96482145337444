import React, { Component } from 'react'
import { collection, doc, getDoc } from 'firebase/firestore';
import db from '../firebase';
import { auth, provider, signInWithPopup, signOut } from '../firebase';
import { LoadProduction } from '../store/MetaStore';
import { Button, Card, CardBody, CardText, Carousel, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { FaChartSimple, FaDisplay, FaGear, FaListCheck, FaMoneyCheckDollar, FaPaintbrush } from 'react-icons/fa6';
import CreateAppUser from './CreateAppUser';
import { FaSquareFacebook, FaWhatsapp } from 'react-icons/fa6';
import { FaMapMarkedAlt } from "react-icons/fa";
import BackgroundUploader from './Common/BackgroundUploader';
import FrameLandingpage from './Common/FrameLandinpage';


export default class LoadAppUser extends Component {
  constructor(props) {
    super(props)
    this.state = LoadProduction.init
    this.state.modalAlert = false
    this.handleConsole = this.handleConsole.bind(this)
  }

  cleanPathname() {
    const { pathname } = window.location;
    const decodedPathname = decodeURIComponent(pathname);
    return decodedPathname.startsWith('/')
      ? decodedPathname.substring('/'.length)
      : decodedPathname;
  }


  updateHeight = () => {
    this.setState({ viewportHeight: window.innerHeight });
  }

  async componentDidMount() {
    window.addEventListener('resize', this.updateHeight);
    this.updateHeight();
    const cleanedPathname = this.cleanPathname()
    const license = await doc(db, '$:__sites-name', cleanedPathname)
    if (cleanedPathname !== '') {
      const isLicenseAvalible = await getDoc(license)
      this.state = isLicenseAvalible.data()
      isLicenseAvalible.exists() ?
        this.setState({
          ...isLicenseAvalible.data(),
          pageExist: true
        }, () => {
          const favicon = document.getElementById('favicon');
          if (favicon) {
            favicon.href = this.state.setFavicon;
          } else {
            const newFavicon = document.createElement('link');
            newFavicon.id = 'favicon';
            newFavicon.rel = 'icon';
            newFavicon.href = this.state.setFavicon;
            document.head.appendChild(newFavicon);
          }
        })
        :
        setTimeout(() => {
          alert('este sitio no existe'); window.location = '/'
        }, 1500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  handleConsole() {
    this.setState({
      utaStep: true,
      frameOn: ''
    })
  }

  handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      this.setState({ user });
    } catch (error) {
      console.error('Error durante el inicio de sesión:', error);
    }
  };

  handleLogout = async () => {
    try {
      await signOut(auth);
      this.setState({ user: null });
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };


  renderContent = () => {
    const { contentMap } = this.state;

    return contentMap.map((content, index) => {
      switch (content.type) {
        case 'Encabezado':
          return <>
            <div >
              <h2 key={index} onClick={() => { this.setState({ idItem: 'a' + index, isFooterVisible: true }) }} id={'a' + index} style={content.style}>{content.title}</h2>
              <br />
            </div>
          </>;
        case 'Texto':
          return <><p key={index} style={content.style}>{content.text}</p><br /></>;
        case 'Imagen':
          return <><img key={index} src={content.src} alt="Contenido" style={{ maxWidth: '100%' }} /><br /></>;
        case 'Video':
          return (
            <video key={index} controls style={{ maxWidth: '100%' }}>
              <source src={content.src} type="video/mp4" />
            </video>
          );
        case 'Carousel':
          return (
            <div key={index} style={{ maxWidth: '100%' }}>
              <Carousel>
                {content.items.map((item, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={item.src}
                      alt={`Slide ${i}`}
                      style={{ width: '100%' }}
                    />
                    {item.caption && (
                      <Carousel.Caption>
                        <h3>{item.caption.title}</h3>
                        <p>{item.caption.text}</p>
                      </Carousel.Caption>
                    )}
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          );
        case 'Navbar':
          return (
            <nav key={index} style={{ backgroundColor: content.style.backgroundColor || '#333', padding: '10px', color: '#fff' }}>
              <h4 style={{ margin: 0 }}>{content.navTitle}</h4>
              <ul style={{ display: 'flex', listStyle: 'none', padding: 0, margin: '10px 0 0 0', gap: '15px' }}>
                {content.links.map((link, i) => (
                  <li key={i}>
                    <a href={link.href} style={{ color: '#fff', textDecoration: 'none' }}>
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          );
        default:
          return null;
      }
    });
  };

  render() {
    const { btnTxtTitle, choiseBtnColor, displayCharts, viewportHeight, choiseColorBackground } = this.state;

    return (
      <>   
        <div className='App no-scroll' style={{ height: viewportHeight }}>
          <BackgroundUploader
            img1={this.state.isEnabledFull ? this.state.srcImgMapBackFull : this.state.defaultImages.full}
            img2={this.state.isEnabledSky ? this.state.srcImgMapBackSky : this.state.defaultImages.sky}
            img3={this.state.isEnabledCenter ? this.state.srcImgMapBackCenter : this.state.defaultImages.center}
            img4={this.state.isEnabledBase ? this.state.srcImgMapBackBase : this.state.defaultImages.base}
            choiseColorBackground={this.state.choiseColorBackground} />
          <div style={this.state.choiseColorBackground}>
            <div className={this.state.choiseStyleMain + ' ' + this.state.frameOn + ' p-fixed '}>
              {this.state.utaStep === false ?
                this.state.avalibleLoginMain === false ? <Button onClick={this.handleConsole} style={choiseBtnColor} className='z-modal' >{btnTxtTitle ? btnTxtTitle[6] : 'Loading...'}
                </Button> : <> <div className='z-modal'>
                  {!this.state.user ? (
                    <div className='z-modal '>
                      <Button onClick={()=>this.setState({user:true})} style={choiseBtnColor} className='z-modal' >{btnTxtTitle ? btnTxtTitle[6] : 'Loading...'}
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className='scroll-y display-wh' style={choiseColorBackground}>{this.renderContent()}</div>
                    </>
                  )}
                </div></> : this.state.modalWelcome === true ? <CreateAppUser config={this.state} /> : <Card>
                  <CardBody>
                    <CardText>{this.state.txtModalWelcome}</CardText>
                  </CardBody>
                </Card>}
            </div>
            <footer className='p-absolute bottom w-100 justify-center z-modal-con'>
              <Row className='  z-100 '>
                <Col>
                  {this.state.isValidUrl ? (
                    <div style={{ bottom: '0', color: 'blue', fontSize: '24px' }}>
                      <FaSquareFacebook className='c-pointer  z-100' onClick={() => { window.location = this.state.pointFaceUrl }} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col>
                  {this.state.isValidMap ? (
                    <div style={{ bottom: '0', fontSize: '24px', }}>
                      <svg width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                          <linearGradient id="googleGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#4285F4" />
                            <stop offset="25%" stopColor="#EA4335" />
                            <stop offset="50%" stopColor="#FBBC05" />
                            <stop offset="75%" stopColor="#34A853" />
                            <stop offset="100%" stopColor="#EA4335" />
                          </linearGradient>
                        </defs>
                        <FaMapMarkedAlt fill="url(#googleGradient)" className='c-pointer z-100' onClick={() => { window.location = this.state.pointMapsUrl }} />
                      </svg>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col>
                  {this.state.isValidWhats ? (
                    <div style={{ bottom: '0', fontSize: '24px', }}>
                      <svg width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                          <linearGradient id="whatsGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#2FBC05" />
                            <stop offset="50%" stopColor="#34A853" />
                            <stop offset="75%" stopColor="#34A853" />
                            <stop offset="100%" stopColor="#414335" />
                          </linearGradient>
                        </defs>
                        <FaWhatsapp fill="url(#whatsGradient)" className='c-pointer z-100' onClick={() => { window.location = this.state.pointSeedWhatsUrl + this.state.pointWhatsUrl }} />
                      </svg>
                    </div>)
                    : (<>
                    </>)}
                </Col>
              </Row>
            </footer>
          </div>
        </div>
      </>
    )
  }
}
